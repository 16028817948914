<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Template Event</h5>
            </div>
            <div class="card">
                <DataTable :value="templateEventList" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="tb1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Template Events</h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Add Template Event" icon="pi pi-plus" class="p-mr-2"
                                @click="addTemplateEventRedirect(data)" />
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column field="name" header=" Event Name" headerStyle="width: 30%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.tb3 ? data.tb3 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Channel Name" headerStyle="width: 25%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <span v-for="(value, index) in data.tb4.split(',')" :key="index">
                                    <span v-if="value == 1"> WhatsApp</span>
                                    <span v-if="value == 2"> RCS</span>
                                    <span v-if="value == 3"> SMS</span>
                                    <span v-if="value == 4"> Email</span>
                                </span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Status" headerStyle="width: 25%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <span v-if="data.tb5 == 1"> Active</span>
                                <span v-if="data.tb5 == 2"> Inactive</span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 10%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editAdminTemplateEventDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import router from '@/router';
export default {
    data() {
        return {
            v$: useValidate(),
            templateEventList: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            localSuperAdminActionId: '',
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.localSuperAdminActionId = localStorage.localSuperAdminActionId;
        this.getAdminTemplateActionsOnly();
        this.getAdminTemplateEvents();
    },
    validations() {
        return {
        };
    },
    methods: {
        addTemplateEventRedirect() {
            router.push({
                name: "addEditSuperAdminEventTemplate",
                params: { eventId: 0 },
            });
        },

        getAdminTemplateEvents() {
            this.loading = true;
            this.ApiService.getSuperAdminTemplateEvents({ actionId: this.localSuperAdminActionId }).then((data) => {
                if (data.status == 200) {
                    this.templateEventList = data.result;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.templateEventList = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },
        getAdminTemplateActionsOnly(ev) {
            this.loading = true;
            this.ApiService.getSuperAdminTemplateActionsOnly(ev).then((data) => {
                if (data.status == 200) {
                    this.templateActionList = data.data;
                    this.loading = false;
                } else {
                    this.templateActionList = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getAdminTemplateEvents({ page_no: event.page, statefilter: this.event_state, loksabhafilter: this.event_loksabha });
        },

        editAdminTemplateEventDialogOpen(e) {
            router.push({
                name: "addEditSuperAdminEventTemplate",
                params: { eventId: e.tb1 },
            });
        }
    },
};
</script>
<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
